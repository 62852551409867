@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import 'reset.scss';

body {
  font-size: (15rem / 16);
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e384d;
}

#content-root {
  overflow: hidden auto;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

#root {
  flex-grow: 1;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #ebedef;
}

::-webkit-scrollbar-thumb {
  background-color: #2e5bff;
  border-radius: 5px;
  border: 1px solid #ebedef;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #2e5bff #ebedef;
}

#modal-root {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

#popups-root {
  position: relative;
}

.text {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
  &-nowrap {
    white-space: nowrap;
  }

  &-uppercase {
    text-transform: uppercase !important;
  }

  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.weight {
  &-normal,
  &-400 {
    font-weight: normal !important;
  }

  &-500 {
    font-weight: 500 !important;
  }

  &-600 {
    font-weight: 600 !important;
  }

  &-bold,
  &-700 {
    font-weight: bold !important;
  }

  &-800 {
    font-weight: 800 !important;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
}

.invisible {
  visibility: hidden !important;
}

.hidden {
  display: none !important;
}

.relative {
  position: relative;
}
