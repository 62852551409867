.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
}
.rc-dropdown-hidden {
  display: none;
}

.rc-dropdown-slide-up-enter,
.rc-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
.rc-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpIn;
  animation-play-state: running;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownIn;
  animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpOut;
  animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownOut;
  animation-play-state: running;
}
@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }
}
@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0 100%;
    transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0 100%;
    transform: scaleY(0);
  }
}
.rc-dropdown-arrow {
  position: absolute;
  border: 1px solid rgba(46, 91, 255, 0.08);
  width: 8px;
  height: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(45deg);
  background-color: #fff;
}
.rc-dropdown-show-arrow.rc-dropdown-placement-topCenter,
.rc-dropdown-show-arrow.rc-dropdown-placement-topLeft,
.rc-dropdown-show-arrow.rc-dropdown-placement-topRight {
  padding-bottom: 6px;
}
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomCenter,
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomLeft,
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomRight {
  padding-top: 6px;
}
.rc-dropdown-placement-topCenter .rc-dropdown-arrow,
.rc-dropdown-placement-topLeft .rc-dropdown-arrow,
.rc-dropdown-placement-topRight .rc-dropdown-arrow {
  bottom: 3px;
  border-top-color: white;
}
.rc-dropdown-placement-topCenter .rc-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.rc-dropdown-placement-topLeft .rc-dropdown-arrow {
  left: 15%;
}
.rc-dropdown-placement-topRight .rc-dropdown-arrow {
  right: 15%;
}
.rc-dropdown-placement-bottomCenter .rc-dropdown-arrow,
.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow,
.rc-dropdown-placement-bottomRight .rc-dropdown-arrow {
  top: 3px;
  border-bottom-color: white;
}
.rc-dropdown-placement-bottomCenter .rc-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow {
  left: 15%;
}
.rc-dropdown-placement-bottomRight .rc-dropdown-arrow {
  right: 15%;
}

.rc-dropdown-arrow:last-child {
  border: transparent;
  box-shadow: none;
}
